var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"info-wrap"},[_c('div',{staticClass:"total-count"},[_vm._v("Total "+_vm._s(_vm.page.total)+" Records")]),_c('div',[_c('shopee-button',{on:{"click":function($event){_vm.visibleUpload = true}}},[_vm._v("Upload")]),_c('shopee-button',{staticClass:"btn-configuration",attrs:{"type":"primary"},on:{"click":_vm.onAddModule}},[_c('shopee-icon',{attrs:{"svg":_vm.addIcon}}),_vm._v(" Add Module ")],1)],1)]),_c('div',{staticClass:"table-wrapper"},[_c('el-table',{attrs:{"row-key":"id","data":_vm.isLoading ? [] : _vm.list,"default-expand-all":false,"tree-props":{
        children: 'children',
        hasChildren: 'hasChildren'
      }}},[_c('el-table-column',{attrs:{"label":"Module"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.moduleName))])]}}])}),_c('el-table-column',{attrs:{"label":"System","width":209 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.parentId ? '' : (row.systemName || '-'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Created Time","width":209 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.parentId ? '' : (_vm.getDateTime(row.createTime) || '-'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Creator Email","width":223 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.parentId ? '' : (row.creatorEmail || '-'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Action","fixed":"right","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(!row.parentId)?_c('div',{staticClass:"operator"},[_c('span',{staticClass:"left-action",on:{"click":function($event){return _vm.onEditModule(row.id)}}},[_vm._v(" Edit ")]),_c('span',{staticClass:"left-action",on:{"click":function($event){return _vm.onDelete(row)}}},[_vm._v(" Delete ")])]):_vm._e()]}}])})],1),(_vm.isLoading)?_c('skeleton'):_vm._e(),(!_vm.isLoading && _vm.list.length === 0)?_c('table-empty',{attrs:{"type":"empty-result","text":_vm.emptySearchText}}):_vm._e(),(!_vm.isLoading && _vm.page.total > 0)?_c('shopee-pagination',{staticClass:"pagination",attrs:{"layout":"pages,sizes,jumper","total":_vm.page.total,"current":_vm.page.page,"page-size":_vm.page.pageSize,"page-sizes":_vm.page.sizes},on:{"page-change":_vm.onPageChange,"page-size-change":_vm.onPageSizeChange}}):_vm._e()],1),_c('modal',{attrs:{"isEdit":_vm.isEdit,"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event},"success-cb":_vm.onSuccessCb}}),(_vm.visibleDelete)?_c('modal-delete',{attrs:{"visible":_vm.visibleDelete,"id":_vm.currentItemInfo.id},on:{"update:visible":function($event){_vm.visibleDelete=$event},"success-cb":_vm.onSuccessCb}}):_vm._e(),(_vm.visibleUpload)?_c('modal-upload',{attrs:{"visible":_vm.visibleUpload},on:{"update:visible":function($event){_vm.visibleUpload=$event},"success-cb":_vm.onSuccessCb}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }